import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useNavigate } from 'react-router';
import Toolbar from './Toolbar';
import {
  useForceLogOut,
  useGetRepMe,
  useGetRepsList,
} from 'src/hooks/repHooks';
import moment from 'moment';
import { Role } from 'src/models';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const RepsList = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedRepsIds, setSelectedRepsIds] = useState([]);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const forceLogOut = useForceLogOut();

  const handleClick = (user_id) => {
    forceLogOut.mutate(user_id);
  };

  const { data, isLoading } = useGetRepsList(page * limit, limit, searchValue);
  const { data: repData } = useGetRepMe();

  let reps = [];
  if (Array.isArray(data)) reps = data;

  const handleSelectAll = (event) => {
    let newSelectedRepsIds;

    if (event.target.checked) {
      newSelectedRepsIds = reps.map((rep) => rep.id);
    } else {
      newSelectedRepsIds = [];
    }

    setSelectedRepsIds(newSelectedRepsIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRepsIds.indexOf(id);
    let newSelectedRepsIds = [];

    if (selectedIndex === -1) {
      newSelectedRepsIds = newSelectedRepsIds.concat(selectedRepsIds, id);
    } else if (selectedIndex === 0) {
      newSelectedRepsIds = newSelectedRepsIds.concat(selectedRepsIds.slice(1));
    } else if (selectedIndex === selectedRepsIds.length - 1) {
      newSelectedRepsIds = newSelectedRepsIds.concat(
        selectedRepsIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedRepsIds = newSelectedRepsIds.concat(
        selectedRepsIds.slice(0, selectedIndex),
        selectedRepsIds.slice(selectedIndex + 1)
      );
    }

    setSelectedRepsIds(newSelectedRepsIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Toolbar searchValue={searchValue} setSearchValue={setSearchValue} />
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRepsIds.length === reps.length}
                        color="primary"
                        indeterminate={
                          selectedRepsIds.length > 0 &&
                          selectedRepsIds.length < reps.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Last login</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created date/time</TableCell>
                    {(repData?.role === Role.ADMIN ||
                      repData?.role === Role.AGENT_SUPERVISOR) && (
                      <TableCell></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reps.map((rep) => (
                    <TableRow
                      hover
                      key={rep.user_id}
                      selected={selectedRepsIds.indexOf(rep.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRepsIds.indexOf(rep.id) !== -1}
                          onChange={(event) => handleSelectOne(event, rep.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{rep.email}</Typography>
                      </TableCell>

                      <TableCell>
                        <Typography>{rep.role}</Typography>
                      </TableCell>

                      <TableCell>
                        {rep.last_active
                          ? moment.unix(rep.last_active).fromNow()
                          : 'not logged in yet'}
                      </TableCell>

                      <TableCell>
                        {rep.is_active ? 'active' : 'inactive'}
                      </TableCell>
                      <TableCell>
                        {moment.unix(rep.registration_date).format('lll')}
                      </TableCell>
                      {(repData?.role === Role.ADMIN ||
                        repData?.role === Role.AGENT_SUPERVISOR) && (
                        <TableCell>
                          <Button
                            onClick={() => navigate(`/app/rep/${rep.id}`)}
                            variant="contained"
                            color="primary"
                            disabled={
                              repData?.role === Role.AGENT_SUPERVISOR &&
                              [
                                Role.AGENT_SUPERVISOR,
                                Role.AGENT_OPS,
                                Role.QA,
                              ].includes(rep.role) === false
                            }
                          >
                            Edit rep
                          </Button>
                          <Button
                            onClick={() => handleClick(rep.user_id)}
                            style={{ marginLeft: 10 }}
                            variant="contained"
                            color="primary"
                          >
                            Log out
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={-1}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 100]}
          />
        </Card>
      )}
    </>
  );
};

RepsList.propTypes = {
  className: PropTypes.string,
};

export default RepsList;
