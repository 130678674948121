import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePcsClient } from 'src/context/pcs-auth-context';

export function useUpdateFacialRecognition() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (data: {
      noFaceStates: string[];
      noFaceCities: string[];
      noFaceZips: string[];
      optInStates: string[];
      optInCities: string[];
      optInZips: string[];
    }) =>
      client(`settings/facial-recognition/update`, {
        method: 'POST',
        data: {
          no_face_states: data.noFaceStates,
          no_face_cities: data.noFaceCities,
          no_face_zips: data.noFaceZips,
          opt_in_states: data.optInStates,
          opt_in_cities: data.optInCities,
          opt_in_zips: data.optInZips,
        },
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('facial_recognition');
        enqueueSnackbar('Facial recognition settings successfully updated', {
          variant: 'success',
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Error setting facial recognition settings: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
}

export function useGetFacialRecognition() {
  const client = usePcsClient();
  const query = useQuery(
    ['facial_recognition'],
    () => client('settings/facial-recognition/get'),
    {
      onError: (error) => {
        console.error(`Error getting facial recognition settings: ${error}`);
      },
      retry: false,
    }
  );
  return query;
}

type UserSettingsPayload = {
  micVolume: number;
};

export function useSetUserSettings() {
  const client = usePcsClient();
  const cache = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    (data: UserSettingsPayload) =>
      client(`settings`, {
        method: 'PATCH',
        data: {
          mic_volume: data.micVolume,
        },
      }),
    {
      onSuccess: () => {
        cache.invalidateQueries('user_settings');
      },
      onError: (error) => {
        enqueueSnackbar(`Error updating user settings: ${error}`, {
          variant: 'error',
        });
      },
    }
  );
}

type UserSettings = {
  mic_volume: number;
};

type UserSettingsResponse = {
  data: UserSettings;
};

export function useGetUserSettings(): UserSettingsResponse {
  const client = usePcsClient();
  const query = useQuery<UserSettings>(
    ['user_settings'],
    () => client('settings'),
    {
      onError: (error) => {
        console.error(`Error getting user settings: ${error}`);
      },
      retry: false,
    }
  );
  return query;
}
