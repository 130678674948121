import React, { useRef, useState, useCallback, useContext } from 'react';
import { PropTypes } from 'prop-types';
import Marker from './Marker';
import { useLiveIndicator } from './AddLiveIndicator';
import {
  Tooltip,
  IconButton,
  SvgIcon,
  makeStyles,
  styled,
} from '@material-ui/core';
import ControlsTooltip from './ControlTooltip';
import { ReactComponent as Siren } from './images/siren.svg';
import { ReactComponent as NoSiren } from './images/no-siren.svg';
import { ReactComponent as Talking } from './images/talking.svg';
import { ReactComponent as Muted } from './images/muted.svg';
import { ReactComponent as SpotlightActive } from './images/spotlight-active.svg';
import { ReactComponent as SpotlightInactive } from './images/spotlight-inactive.svg';
import { AudioSocketState } from './kvs-camera/useStreamRecorder';
import { MousePointer as MouseIcon } from 'react-feather';
import {
  MetricsContext,
  createOpenMetricDispatch,
} from 'src/context/Metrics-context';
import {
  SPOTLIGHT_COOLDOWN_SECONDS,
  SPOTLIGHT_DURATION_SECONDS,
  useActivateSpotlight,
} from 'src/hooks/cameraHooks';

function Controls(props) {
  const {
    wsState,
    progressEl,
    volumeEl,
    controls,
    isPlaying,
    volume,
    muted,
    currentTime,
    duration,
    markers,
    onPlayClick,
    onPauseClick,
    onProgressClick,
    onVolumeClick,
    onMuteClick,
    onFullScreenClick,
    onMarkerClick,
    isTalking,
    onRetry,
    onStartTalkingClick,
    onStopTalkingClick,
    disableVolumeAndMicrophone = false,
    isSiren,
    onStartSirenClick,
    isTalkOnHold = false,
    showUnmuteOnHoverIcon = false,
    changeUnmuteOnHover = () => {},
    eventId,
    uuid,
    sid,
  } = props;

  const [hasClickedSirenOnce, sethasClickedSirenOnce] = useState(false);
  const isHoldRef = useRef(null);
  const liveIndicator = useLiveIndicator();
  const classes = useStyles();
  const sirenTitle = `Click again to ${isSiren ? 'stop' : 'start'} Siren!`;
  const [_, dispatch] = useContext(MetricsContext);
  const [isSpotlightActive, setIsSpotlightActive] = useState(false);

  const setActivateSpotlight = useActivateSpotlight();

  const onStartSirenClickHandler = (event) => {
    if (hasClickedSirenOnce) {
      onStartSirenClick(event);
      setTimeout(() => {
        sethasClickedSirenOnce(false);
      }, 2000);
    }
    sethasClickedSirenOnce(!hasClickedSirenOnce);
  };

  const getTimeCode = useCallback((secs) => {
    const secondsNumber = secs ? parseInt(String(secs), 10) : 0;
    const hours = Math.floor(secondsNumber / 3600);
    const minutes = Math.floor((secondsNumber - hours * 3600) / 60);
    const seconds = secondsNumber - hours * 3600 - minutes * 60;
    let hoursStr = String(hours);
    let minutesStr = String(minutes);
    let secondsStr = String(seconds);

    if (hours < 10) {
      hoursStr = `0${hours}`;
    }
    if (minutes < 10) {
      minutesStr = `0${minutes}`;
    }
    if (seconds < 10) {
      secondsStr = `0${seconds}`;
    }

    return `${
      hoursStr !== '00' ? `${hoursStr}:` : ''
    }${minutesStr}:${secondsStr}`;
  }, []);

  const durationTimeCode = getTimeCode(Math.ceil(duration));
  const currentTimeCode =
    currentTime !== duration ? getTimeCode(currentTime) : durationTimeCode;

  const onVolumeClickHandler = (e) => {
    changeUnmuteOnHover(false);
    onVolumeClick(e);
  };

  const onMuteClickHandler = () => {
    if (disableVolumeAndMicrophone) {
      return;
    }
    changeUnmuteOnHover(false);
    onMuteClick();
  };

  const enableUnmuteOnHover = () => {
    if (disableVolumeAndMicrophone) {
      return;
    }
    changeUnmuteOnHover(true);
    if (muted) {
      onMuteClick();
    }
  };

  // Activate the spotlight - hardware turns off automatically after requested duration
  // Then update the icon after that duration - does not actually query device for spotlight status
  const activateSpotlight = () => {
    // Request to backend to activate spotlight
    setActivateSpotlight.mutate({ sid, uuid });

    setIsSpotlightActive(true);

    const openMetricDispatch = createOpenMetricDispatch(dispatch);
    openMetricDispatch({
      metricName: 'spotlight-activated',
      payload: { eventId },
    });

    setTimeout(() => {
      setIsSpotlightActive(false);
      openMetricDispatch({
        metricName: 'spotlight-deactivated-automatic',
        payload: { eventId },
      });
    }, SPOTLIGHT_DURATION_SECONDS * 1000 + SPOTLIGHT_COOLDOWN_SECONDS * 1000);
  };

  const renderTalkingButton = (disabled = false) => (
    <div className="mute-container">
      {disabled ? <div className="cross-line"></div> : null}

      <button
        type="button"
        disabled={!liveIndicator}
        title={isTalking ? 'Mute' : 'Un-mute'}
        className={`btn-icon ${liveIndicator ? '' : classes.disabled}`}
        style={{ backgroundColor: 'transparent' }}
        onClick={
          !isTalkOnHold && !disabled
            ? isTalking
              ? onStopTalkingClick
              : onStartTalkingClick
            : undefined
        }
        onMouseDown={
          isTalkOnHold
            ? (e) => {
                if (!e.button || e.button === 1) {
                  isHoldRef.current = setTimeout(() => {
                    isHoldRef.current = null;
                    onStartTalkingClick();
                  }, 600);
                }
              }
            : undefined
        }
        onMouseUp={
          isTalkOnHold
            ? (e) => {
                if (!e.button || e.button === 1) {
                  if (isHoldRef.current) {
                    clearTimeout(isHoldRef.current);
                  } else {
                    onStopTalkingClick();
                  }
                }
              }
            : undefined
        }
      >
        {isTalking ? <Talking /> : <Muted />}
      </button>
    </div>
  );

  return (
    <div className="react-video-controls">
      {controls.includes('play') ? (
        <button
          type="button"
          className={isPlaying ? 'pause' : 'play'}
          onClick={isPlaying ? onPauseClick : onPlayClick}
        >
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      ) : null}

      {controls.includes('time') ? (
        <div className="time">
          {currentTimeCode}/{durationTimeCode}
        </div>
      ) : null}

      {controls.includes('progress') ? (
        <div className="progress-wrap">
          <progress
            ref={progressEl}
            max="100"
            onClick={onProgressClick}
            // onMouseMove={onProgressClick} // Demonstrates idea for using mouse hover to show thumbnail
          >
            0% played
          </progress>
          {markers &&
            markers.map((marker, index) => {
              return (
                <Marker
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  marker={marker}
                  duration={duration}
                  onMarkerClick={onMarkerClick}
                />
              );
            })}
        </div>
      ) : null}

      {controls.includes('reload') ? (
        <button type="button" className="reload" onClick={onRetry}>
          Reload
        </button>
      ) : null}

      {showUnmuteOnHoverIcon ? (
        <Tooltip title="Enable unmute on hover">
          <IconButton onClick={enableUnmuteOnHover}>
            <SvgIcon fontSize="small">
              <MouseIcon color="#fb8d00" />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      ) : null}

      {controls.includes('volume') ? (
        <div className="volume-wrap">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          {!disableVolumeAndMicrophone && (
            <progress
              ref={volumeEl}
              max="100"
              value={volume * 100}
              onClick={onVolumeClickHandler}
            >
              {volume * 100}% volume
            </progress>
          )}
          {disableVolumeAndMicrophone ? (
            <ControlsTooltip
              className="volume-tooltip"
              title={
                <p className="volume-tooltip-text">
                  This Customer has disabled their microphone. You will not be
                  able to use two way communication through this camera.
                </p>
              }
              placement="top-end"
            >
              <div className="mute-container">
                <div className="cross-line"></div>
                <button
                  type="button"
                  className={muted ? 'no-volume' : 'volume'}
                  onClick={onMuteClickHandler}
                >
                  Volume
                </button>
              </div>
            </ControlsTooltip>
          ) : (
            <button
              type="button"
              className={muted ? 'no-volume' : 'volume'}
              onClick={onMuteClickHandler}
            >
              Volume
            </button>
          )}
        </div>
      ) : null}

      {controls.includes('spotlight') && (
        <button
          type="button"
          title="Spotlight"
          className="btn-icon"
          onClick={activateSpotlight}
          disabled={isSpotlightActive}
        >
          {isSpotlightActive ? <SpotlightActive /> : <SpotlightInactive />}
        </button>
      )}

      {controls.includes('siren') ? (
        <button
          type="button"
          disabled={!liveIndicator}
          title={
            hasClickedSirenOnce ? sirenTitle : isSiren ? 'No-siren' : 'Siren'
          }
          className={`btn-icon ${liveIndicator ? '' : classes.disabled}`}
          onClick={onStartSirenClickHandler}
        >
          {hasClickedSirenOnce ? (
            <NoSiren style={{ fill: 'orange' }} />
          ) : isSiren ? (
            <Siren />
          ) : (
            <NoSiren />
          )}
        </button>
      ) : null}

      {controls.includes('talk') ? (
        <div style={{ position: 'relative' }}>
          {(wsState === AudioSocketState.CONNECTING ||
            wsState === AudioSocketState.CLOSING) &&
          isTalkOnHold ? (
            <div
              className="lds-ring"
              style={{
                position: 'absolute',
                zIndex: 0,
                top: 2,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : null}
          {isTalking && isTalkOnHold && wsState === AudioSocketState.OPEN ? (
            <div
              className="lds-ripple"
              style={{
                position: 'absolute',
                zIndex: 0,
                pointerEvents: 'none',
                top: 2,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <div></div>
              <div></div>
            </div>
          ) : null}
          {disableVolumeAndMicrophone ? (
            <ControlsTooltip
              className="volume-tooltip"
              title={
                <p className="volume-tooltip-text">
                  This Customer has disabled their microphone. You will not be
                  able to use two way communication through this camera.
                </p>
              }
              placement="top-end"
            >
              {renderTalkingButton(true)}
            </ControlsTooltip>
          ) : (
            renderTalkingButton(false)
          )}
        </div>
      ) : null}

      {controls.includes('full-screen') ? (
        <button
          type="button"
          className="full-screen"
          onClick={onFullScreenClick}
        >
          FullScreen
        </button>
      ) : null}
    </div>
  );
}

Controls.propTypes = {
  wsState: PropTypes.number,
  progressEl: PropTypes.object,
  volumeEl: PropTypes.object,
  controls: PropTypes.array,
  isTalkOnHold: PropTypes.bool,
  isPlaying: PropTypes.bool,
  volume: PropTypes.number,
  muted: PropTypes.bool,
  currentTime: PropTypes.number,
  duration: PropTypes.number,
  disableVolumeAndMicrophone: PropTypes.bool,
  markers: PropTypes.array,
  onRetry: PropTypes.func,
  onPlayClick: PropTypes.func,
  onPauseClick: PropTypes.func,
  onProgressClick: PropTypes.func,
  onVolumeClick: PropTypes.func,
  onMuteClick: PropTypes.func,
  onFullScreenClick: PropTypes.func,
  onMarkerClick: PropTypes.func,
  isTalking: PropTypes.bool,
  onStartTalkingClick: PropTypes.func,
  onStopTalkingClick: PropTypes.func,
  isSiren: PropTypes.bool,
  onStartSirenClick: PropTypes.func,
  showUnmuteOnHoverIcon: PropTypes.bool,
  changeUnmuteOnHover: PropTypes.func,
  eventId: PropTypes.number,
  uuid: PropTypes.string,
  sid: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
  disabled: {
    stroke: theme.palette.grey[400],
    fill: theme.palette.grey[400],
  },
}));

export default Controls;
